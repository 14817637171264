// @autogenerated 4ddc8be645094e1893435c02507000eb

export enum AttachmentUploadSource {
	Unknown = 0,
	SelectedFromGallery = 1,
	CapturedFromCamera = 2,
	ScannedDocument = 3,
	UploadedFromWeb = 4,
	SharedWithApp = 5,
}

export enum CustomerLeadSource {
	Unknown = 0,
	Referral = 1,
	Google = 2,
	Facebook = 3,
	Yelp = 4,
	Billboard = 5,
	CarWrap = 6,
}

/*
Notifications types sent to customers
*/
export enum CustomerNotificationType {
	EstimateScheduled = 0,
	WorkScheduled = 1,
	RequestForRating = 2,
}

export enum EmailVerifyStatus {
	NotVerified = 0,
	Verified = 1,
	AlreadyVerified = 2,
}

export enum ExpenseType {
	LaborExpense = 0,
	ReceiptExpense = 1,
	MaterialExpense = 2,
}

export enum IntegrationInstanceStatus {
	Enabled = 0,
	Disabled = 1,
	InProgress = 2,
}

export enum IntegrationStatus {
	Enabled = 0,
	Disabled = 1,
	ComingSoon = 2,
}

export enum IntegrationType {
	GoogleAPI = 0,
	OfficeAPI = 1,
}

export enum InvoicePaymentMethod {
	Cash = 0,
	Credit = 1,
	Check = 2,
	BankTransfer = 3,
}

export enum InvoiceStatus {
	Draft = 0,
	Open = 1,
	Paid = 2,
	Void = 3,
	Uncollectible = 4,
	Deleted = 5,
	Sent = 6,
}

export enum PaymentDueTerm {
	OnReceipt = 0,
	Within07Days = 1,
	Within14Days = 2,
	Within21Days = 3,
	Within30Days = 4,
	Within60Days = 5,
	Within90Days = 6,
}

export enum PaymentStatus {
	Unpaid = 0,
	Cash = 1,
	Credit = 2,
	Check = 3,
	Zelle = 4,
	Paypal = 5,
	Stripe = 6,
}

/*
How a given worker should be paid to performing a given project.
*/
export enum PaymentUnit {
	Dollars = 0,
	Percentage = 1,
}

export enum Platform {
	Unknown = 0,
	Android = 1,
	iOS = 2,
}

export enum PreviewProcessingStatus {
	Pending = 0,
	Processing = 1,
	Success = 2,
	Failure = 3,
}

/*
Different roles that a worker can have in a project
*/
export enum ProjectRole {
	Undefined = 0,
	Installer = 1,
	Salesman = 2,
	Helper = 3,
	Referrer = 4,
}

export enum ProjectStatus {
	NeedProposal = 0,
	WaitingForAcceptance = 1,
	NeedInstallation = 2,
	InstallationCompleted = 3,
	PaidToCompany = 4,
	DEPRECATED_PaidToWorkers = 5,
	Canceled = 6,
}

export enum RoofType {
	Unknown = 0,
	TileRoof = 1,
	MetalTileRoof = 2,
	ShingleRoof = 3,
	FlatRoof = 4,
}

/*
There are three possible priorities for a single task: low, medium, and high. The
    normal priority is here but just for historical reasons and we shouldn't use it since
    that lead to confussions with Medium priority.
*/
export enum TaskPriority {
	Low = 0,
	Normal = 1,
	Medium = 2,
	High = 3,
}

export enum TaskStatus {
	Undone = 0,
	Done = 1,
}

/*
General notifications for state changes
*/
export enum UserChangeType {
	CreateProject = 0,
	UpdateProject = 1,
	DeleteProject = 2,
	CreateCustomer = 3,
	UpdateCustomer = 4,
	DeleteCustomer = 5,
	CreateWorker = 6,
	UpdateWorker = 7,
	DeleteWorker = 8,
	CreateProjectNote = 9,
	UpdateProjectNote = 10,
	DeleteProjectNote = 11,
	DEPRECATED_AddProjectImages = 12,
	DEPRECATED_DeleteProjectImage = 13,
	UpdateProjectStatus = 14,
	UpdateProjectPrice = 15,
	UpdateInventory = 16,
	UpdateProjectProposal = 17,
	DeleteProjectProposal = 18,
	CreateProjectPhase = 19,
	DeleteProjectPhase = 20,
	DEPRECATED_CreateProduct = 21,
	DEPRECATED_EditProduct = 22,
	ChangeOwnPassword = 23,
	UpdateAvatar = 24,
	DEPRECATED_WorkforceCreate = 25,
	DEPRECATED_WorkforceDelete = 26,
	DEPRECATED_WorkforceUpdate = 27,
	DEPRECATED_SMSUpload = 28,
	DEPRECATED_CreateExpense = 29,
	DEPRECATED_UpdateExpense = 30,
	DEPRECATED_DeleteExpense = 31,
	EditProjectPhase = 32,
	AddAttachments = 33,
	DeleteAttachments = 34,
	EditCompanyDetails = 35,
	UpdateCompanyLogo = 36,
	CreateTask = 37,
	UpdateTask = 38,
	DeleteTask = 39,
	DEPRECATED_DeleteProduct = 40,
	GenerateAppointment = 41,
	CreateInvoice = 42,
	UpdateInvoiceStatus = 43,
	CreateTax = 44,
	ConnectStripe = 45,
	UpdateInvoice = 46,
	CreateInvoiceOriginalItem = 47,
	AddInvoicePayment = 48,
	DEPRECATED_CreateTimeCard = 49,
	DEPRECATED_UpdateTimeCard = 50,
	DEPRECATED_DeleteTimeCard = 51,
	UpdateCustomerAvatar = 52,
	SubscribeToPlan = 53,
	DeleteAvatar = 54,
	DeleteCompanyLogo = 55,
	DeleteCustomerAvatar = 56,
	RecordPushNotificationOpened = 57,
}
